import { createSlice } from "@reduxjs/toolkit";
import {
  PRODUCT_DETAIL_F,
  PRODUCT_DETAIL_S,
  PRODUCT_LIST_F,
  PRODUCT_LIST_S,
} from "constants/reducer";

export interface IProductSlice {
  productList: any;
  productDetail: any;
}

const initialState: IProductSlice = {
  productList: {},
  productDetail: {},
};
// Reducer
const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(PRODUCT_LIST_S, (state, action: any) => {
      state.productList = action.payload.data;
    });
    builder.addCase(PRODUCT_LIST_F, (state) => {
      state.productList = [];
    });
    builder.addCase(PRODUCT_DETAIL_S, (state, action: any) => {
      state.productDetail = action.payload.data;
    });
    builder.addCase(PRODUCT_DETAIL_F, (state) => {
      state.productDetail = [];
    });
  },
});

export default userSlice.reducer;
