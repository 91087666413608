import moment from "moment";

export const validateExpiryDate = (_, value) => {
  if (!value) {
    return Promise.reject();
  }

  const selectedDate = moment(value, "YYYY-MM-DD");
  const currentDate = moment();

  if (!selectedDate.isValid()) {
    return Promise.reject(new Error("Invalid date format"));
  }

  if (selectedDate.isBefore(currentDate, "day")) {
    return Promise.reject(new Error("Expiry date must be in the future"));
  }

  return Promise.resolve();
};

export const validatePoints = (_, value) => {
  if (value && parseFloat(value) < 0) {
    return Promise.reject(new Error("Point cannot be negative"));
  }

  return Promise.resolve();
};

export const validateName = (_, value) => {
  const regex = /^[a-zA-Z0-9\s]+$/;

  if (value && !regex.test(value)) {
    return Promise.reject(
      new Error("Name should only contain letters and spaces")
    );
  } if (value && !value.trim()) {
    return Promise.reject(
      new Error("Please enter a name")
    );
  }

  return Promise.resolve();
};

export const validateNumber = (_, value) => {
  const regex = /^[0-9]+$/;
  if (value && !regex.test(value)) {
    return Promise.reject(new Error("Only digit allow"));
  } else if (value && value?.length !== 10) {
    return Promise.reject(new Error("Mobile Number should be 10 digit"));
  }

  return Promise.resolve();
};
export const validatePassword = (_, value) => {
  if (value && value.length < 6) {
    return Promise.reject(
      new Error("Password must be at least 6 characters long")
    );
  }

  return Promise.resolve();
};
