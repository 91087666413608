import {
  API_CHANGEPASSWORD,
  API_DASHBORD_COUNT,
  API_IMAGE_DELETE,
  API_IMAGE_UPLOAD,
} from "constants/api";

export const dashBordCount: any = () => ({
  type: "API",
  hideLoader: true,
  payload: {
    url: API_DASHBORD_COUNT,
    method: "POST",
    hideLoader: false,
  },
});

export const imageUpload: any = (data) => ({
  type: "API",
  payload: {
    url: API_IMAGE_UPLOAD,
    method: "POST",
    hideLoader: false,
    data: data,
  },
});
export const imageDelete: any = (data) => ({
  type: "API",
  payload: {
    url: API_IMAGE_DELETE,
    method: "POST",
    hideLoader: false,
    data: data,
  },
});
export const changePassword: any = (data) => ({
  type: "API",
  payload: {
    url: API_CHANGEPASSWORD,
    method: "POST",
    hideLoader: false,
    data: data,
  },
});
