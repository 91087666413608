import { createSlice } from "@reduxjs/toolkit";
import {
  ORDER_DETAIL_F,
  ORDER_DETAIL_S,
  ORDER_LIST_F,
  ORDER_LIST_S,
} from "constants/reducer";

export interface IOrderSlice {
  orderList: any;
  orderDetail: any;
}

const initialState: IOrderSlice = {
  orderList: {},
  orderDetail: {},
};
// Reducer
const orderSlice = createSlice({
  name: "order",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ORDER_LIST_S, (state, action: any) => {
      state.orderList = action.payload.data;
    });
    builder.addCase(ORDER_LIST_F, (state) => {
      state.orderList = [];
    });
    builder.addCase(ORDER_DETAIL_S, (state, action: any) => {
      state.orderDetail = action.payload.data;
    });
    builder.addCase(ORDER_DETAIL_F, (state) => {
      state.orderDetail = [];
    });
  },
});

export default orderSlice.reducer;
