import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "Types/Entity/UserEntity";
import { USER_LIST_F, USER_LIST_S } from "constants/reducer";


export interface IUserSlice {
  userList: any;
  userInfo: IUser | {};
}

const initialState: IUserSlice = {
  userList: {},
  userInfo: {},
};


// Reducer
const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(USER_LIST_S, (state, action: any) => {
      state.userList = action.payload.data;
    });
    builder.addCase(USER_LIST_F, (state) => {
      state.userList = [];
    });
  },
});

export default userSlice.reducer;
