// API ENDPOINTS
export const API_LOGIN: string = `admin/login`;

//user list api
export const API_USER_ADD: string = "/admin/user/create";
export const API_USER_EDIT: string = "/admin/user/update";
export const API_USER_LIST: string = `/admin/user/list`;

//dashbord count api
export const API_DASHBORD_COUNT: string = "/admin/dashboard/count";

//product crud api
export const API_PRODUCT_LIST: string = "/admin/product/list";
export const API_PRODUCT_ADD: string = "/admin/product/create";
export const API_PRODUCT_UPDATE: string = "/admin/product/update";
export const API_PRODUCT_GET: string = "/admin/product/get/";
export const API_PRODUCT_ACTIVE: string = "/admin/product/activeInActive/";

//Image Upaload
export const API_IMAGE_UPLOAD: string = "/local-images/uploadMultipleFile";
export const API_IMAGE_DELETE: string = "/local-images/deleteMultipleFile";
//Change Password
export const API_CHANGEPASSWORD: string = "/admin/changePassword";

//order Crude
export const API_ORDER_LIST: string = "/admin/order/list";
export const API_ORDER_GET: string = "/admin/order/get/";
