import React from 'react';
import AdminPanelHeader from './header';

const Layout = () => {

    return (
        <>
            <AdminPanelHeader />
        </>
    )
}

export default Layout;