import HashLoader from "react-spinners/BounceLoader";
import { useAppSelector } from "services/store";
import './index.scss'


const Loading = (props) => {
    const { isLoading } = useAppSelector(state => state?.auth);

    return (
        <>
            {isLoading ? <div className={'container'}>
                <HashLoader
                    color={'rgb(17 80 129)'}
                    loading={true}
                    className="hasLoader"
                    size={window.innerHeight / 9}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div> : ''}
        </>
    );
};

export default Loading;