import React, { useState } from "react";
import {
  Layout,
  Menu,
  Avatar,
  Dropdown,
  MenuProps,
  Form,
  Modal,
  Input,
  Button,
  message,
} from "antd";
import {
  DesktopOutlined,
  UsergroupAddOutlined,
  ShoppingOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authFail } from "../../../services/AuthSlice";
import { ROUTES } from "constants/routes";
import "./header.scss";
import { changePassword } from "services/DashbordSlice/action";
import { validatePassword } from "utils/validation";
import { IApiError, IApiSuccess } from "Types/Common";
import { toAbsoluteUrl } from "utils";

interface Password {
  currentPassword: number;
  newPassword: string;
}
const { Header, Sider, Content } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

const getItem = (key, label, link, icon, children?: MenuItem[]) => {
  if (link) {
    return {
      key,
      label,
      link,
      icon,
    };
  } else {
    return {
      key,
      label,
      icon,
      children,
    };
  }
};

const menuItems = [
  getItem(
    ROUTES.dashboard,
    <Link to={ROUTES.dashboard}>Dashboard</Link>,
    ROUTES.userManagament,
    <DesktopOutlined />
  ),
  getItem(
    ROUTES.userManagament,
    <Link to={ROUTES.userManagament}>Users</Link>,
    ROUTES.userManagament,
    <UsergroupAddOutlined />
  ),
  getItem(
    ROUTES.productManagament,
    <Link to={ROUTES.productManagament}>Products</Link>,
    ROUTES.productManagament,
    <ShopOutlined />
  ),
  getItem(
    ROUTES.orderManagament,
    <Link to={ROUTES.orderManagament}>Orders</Link>,
    ROUTES.orderManagament,
    <ShoppingOutlined />
  ),
];

const AdminPanelHeader = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const handleLogout = () => {
    // Implement your logout logic here
    dispatch(authFail({}));
    // redirect to the login page or clear user session
    navigate(ROUTES.signIn);
  };

  const items = [
    { key: "logout", label: "Logout", icon: null, onClick: handleLogout },
    {
      key: "changePassword",
      label: "ChangePassword",
      icon: null,
      onClick: () => setIsModalVisible(true),
    },
  ];

  const handleCancle = () => {
    setIsModalVisible(false);
    form.resetFields();
  };
  const handleAddFormSubmit = (values: Password) => {
    const payload = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
      _id: "649c0a225afda926b1a08000",
    };
    dispatch(changePassword(payload))
      .then((res: IApiSuccess) => {
        setIsModalVisible(false);
        message.success(res.message);
        dispatch(authFail({}));
      })
      .catch((err: IApiError) => {
        message.error(err.message);
      });
  };
  return (
    <Layout className="headerMain">
      <Header className="header">
        <div className="logo">
          <Link to={ROUTES.dashboard}>
            <img src={toAbsoluteUrl('/images/logo.jpg')} alt="logo-ultratech" className="logo" />
          </Link>
        </div>
        <div className="profile">
          <Dropdown menu={{ items }} trigger={["click", "hover"]}>
            <Avatar size="large" src="images/profile.png" />
            {/* Add your profile information or other header content here */}
          </Dropdown>
        </div>
      </Header>
      <Modal
        title={"Change Password"}
        open={isModalVisible}
        onCancel={handleCancle}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleAddFormSubmit}>
          <Form.Item
            name="currentPassword"
            label="Current Password"
            rules={[
              { required: true, message: "Please enter a current password" },
              { validator: validatePassword },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              { required: true, message: "Please enter a new password" },
              { validator: validatePassword },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="confirm Password"
            rules={[
              {
                required: true,
                message: "Please enter a confirm password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  } else if (!value && value === "") {
                    return Promise.reject("Please enter a confirm password ");
                  } else {
                    return Promise.reject(
                      new Error(
                        "The confirm password that you entered do not match with new password"
                      )
                    );
                  }
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>

          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>

      <Layout>
        <Sider collapsible>
          <Menu
            items={menuItems}
            theme="dark"
            defaultSelectedKeys={[ROUTES.dashboard]}
            selectedKeys={[location.pathname]}
            mode="inline"
          />
        </Sider>
        <Layout className="contentMain">
          <Content className="content">
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AdminPanelHeader;
