export const LOGIN_S: string = `LOGIN_S`;
export const LOGIN_F: string = `LOGIN_F`;

export const USER_LIST_S: string = `USER_LIST_S`;
export const USER_LIST_F: string = `USER_LIST_F`;

export const PRODUCT_LIST_S: string = `PRODUCT_LIST_S`;
export const PRODUCT_LIST_F: string = `PRODUCT_LIST_F`;

export const PRODUCT_DETAIL_S: string = `PRODUCT_DETAIL_S`;
export const PRODUCT_DETAIL_F: string = `PRODUCT_DETAIL_F`;

export const ORDER_LIST_S: string = `ORDER_LIST_S`;
export const ORDER_LIST_F: string = `ORDER_LIST_F`;

export const ORDER_DETAIL_S: string = `ORDER_DETAIL_S`;
export const ORDER_DETAIL_F: string = `ORDER_DETAIL_F`;