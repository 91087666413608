import React, { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthGuard from "../components/AuthGuard";
import { ROUTES } from "constants/routes";
import Layout from "components/layout";

const SignIn = lazy(() => import("./SignIn"));
const Dashboard = lazy(() => import("./Dashboard"));
const UserManagament = lazy(() => import("./UserManagement"));
const ProductManagament = lazy(() => import("./ProductCrud"));
const OrderManagament = lazy(() => import("./OrderCrud"));
const Routing = () => {
  return (
    <Routes>
      <Route path={ROUTES.signIn} element={<SignIn />} />
      <Route
        path={ROUTES.default}
        element={
          <AuthGuard>
            <Layout />
          </AuthGuard>
        }
      >
        <Route index path={ROUTES.dashboard} element={<Dashboard />} />
        <Route path={ROUTES.userManagament} element={<UserManagament />} />
        <Route
          path={ROUTES.productManagament}
          element={<ProductManagament />}
        />
        <Route path={ROUTES.orderManagament} element={<OrderManagament />} />
        <Route
          path={ROUTES.default}
          element={<Navigate replace to={ROUTES.dashboard} />}
        />
      </Route>
      <Route path="*" element={<Navigate replace to={ROUTES.default} />} />
    </Routes>
  );
};

export default Routing;
